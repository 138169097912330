import React, { useState } from 'react';
// import Link from 'next/link';
import { connect } from 'react-redux';

import css from './Header.less';

const Header = props => {
  const [hover, setHover] = useState(false);

  const handleSelect = item => {
    const { dispatch } = props;
    dispatch({
      type: 'city/updateCity',
      payload: {
        currentCity: item,
      },
    });
    setHover(false);
  };

  const { city } = props;
  const { cityList, currentCity } = city;
  return (
    <header className={css.content}>
      <div className={css.contentMain}>
        <div className={css.left}>
          <p className={css.logo}>新得宝贝 - 母婴服务，放心选</p>
          <div
            className={`${css.showCityBox} ${hover ? css.active : ''}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <div className={css.currentCity}>当前位置：{currentCity.name}</div>
            <div className={css.hoverBox}>
              <p className={css.currentCityHover}>
                当前位置：{currentCity.name}
              </p>
              <div>
                {cityList.map(item => {
                  return (
                    <button
                      className={`${css.cityItem} ${
                        item.id === currentCity.id ? css.selected : ''
                      }`}
                      key={item.id.toString()}
                      type="button"
                      onClick={() => handleSelect(item)}
                    >
                      {item.name}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div>
          {/*
          <Link href="/shop">
            <a className={css.rightItem}>商城</a>
          </Link>
          */}
          {/* <p className={css.rightItem}>登陆</p>
          <p className={css.rightItem}>注册</p> */}
        </div>
      </div>
    </header>
  );
};

export default connect(({ city }) => ({
  city,
}))(Header);
