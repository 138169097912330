import React, { useState } from 'react';
import css from './Advantage.less';

const certification = require('~/static/index/certification.png');
const inspection = require('~/static/index/inspection.png');
const protection = require('~/static/index/protection.png');

const cellOne = require('~/static/index/cell_1.png');
const cellTwo = require('~/static/index/cell_2.png');
const cellThree = require('~/static/index/cell_3.png');
const cellFour = require('~/static/index/cell_4.png');

const Advantage = () => {
  const [procedure] = useState([
    {
      icon: cellOne,
      title: '怀孕',
      msg: '产科建档、私立医院、孕早期安全、孕期营养',
    },
    {
      icon: cellTwo,
      title: '孕期',
      msg: '月子会所、月嫂、产检答疑、四维彩超、孕期瑜伽、孕妇写真',
    },
    {
      icon: cellThree,
      title: '产后',
      msg: '产后修复、开奶通乳、新手爸妈指导、育儿嫂、宝宝照、产后专家答疑',
    },
    {
      icon: cellFour,
      title: '亲子',
      msg:
        '亲子游泳、早教、日托、亲子写真、儿童保健、家庭保险、亲子游玩、启蒙英语',
    },
  ]);

  const [guarantee] = useState([
    {
      icon: certification,
      title: '商户资质验证',
      msg:
        '平台商户营业执照等相关证件、执业资质均经过新得宝贝严格审核，杜绝违规经营机构',
    },
    {
      icon: inspection,
      title: '真实用户考察',
      msg: '平台商户由新得宝贝平台全职员工、真实用户进行过实地考察及体验服务',
    },
    {
      icon: protection,
      title: '平台消费保障',
      msg:
        '根据业务类型不同，新得宝贝推出了\n"随心退"、"先行赔付"等措施，保障消费者利益',
    },
  ]);

  return (
    <div className={css.contentMain}>
      {/* 全周期优选 */}
      <h2 className={`mainContTitle ${css.mainContTitle}`}>
        - 全周期优选：孕产育一站式服务 -
      </h2>
      <ul className={`flex-space-between ${css.caseBox}`}>
        {procedure.map(item => {
          return (
            <li className={css.serverItem} key={item.title}>
              <div className={css.iconBox}>
                <img className={css.caseIcon} alt="avatar" src={item.icon} />
              </div>
              <p className={css.caseTitle}>{item.title}</p>
              <p className={css.caseMsg}>{item.msg}</p>
            </li>
          );
        })}
      </ul>
      {/* 消费保障 */}
      <p className={`mainContTitle ${css.mainContTitle}`}>
        严格的准入流程，完善的消费保障
      </p>
      <ul className={`flex-space-between ${css.caseBox}`}>
        {guarantee.map(item => {
          return (
            <li className={css.caseItem} key={item.title}>
              <img className={css.caseIcon} alt="avatar" src={item.icon} />
              <p className={css.caseTitle}>{item.title}</p>
              <p className={css.caseMsg}>{item.msg}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Advantage;
