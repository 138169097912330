import React from 'react';

import Header from '~/components/index/Header/Header';
import Category from '~/components/index/Category/Category';

import css from './Banner.less';

const codeIcon = require('~/static/index/code.png');
const bannerTitle = require('~/static/index/banner_title.png');

const Banner = () => {
  return (
    <div className={css.content}>
      <div className={css.contentMain}>
        {/* 首页header组件 */}
        <Header />
        {/* 品类菜单 */}
        <div className={css.categoryContainer}>
          <Category />
        </div>
        <img className={css.title} src={bannerTitle} alt="bg" />
        <img className={css.qrcode} src={codeIcon} alt="qrcode" />
      </div>
    </div>
  );
};

export default Banner;
