import React, { useState, useEffect } from 'react';
import css from './WeChat.less';

const wxappQrcode = require('~/static/qrcode/xinde.png');

const WeChat = () => {
  const [isCompleteCalcul, setIsCompleteCalcul] = useState(false);
  const [isLessThan1320, setIsLessThan1320] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [wxapp] = useState({
    qrcode: wxappQrcode,
    msg: '扫码使用小程序',
  });

  const handleResize = () => {
    const clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    if (clientWidth <= 1320) {
      if (!isLessThan1320) {
        setIsLessThan1320(true);
      }
    } else if (isLessThan1320) {
      setIsLessThan1320(false);
    }
    if (!isCompleteCalcul) {
      setIsCompleteCalcul(true);
    }
    return true;
  };

  const handleScroll = () => {
    const clientHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const fixedValue = clientHeight - 169 - 41 - 30; // 页面height - 二维码浮层height - 距离底部magin - 滚动顶部时据顶部margin
    if (scrollTop >= fixedValue) {
      if (!isFixed) {
        setIsFixed(true);
      }
    } else if (isFixed) {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isLessThan1320]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isFixed, isLessThan1320]);

  if (!isCompleteCalcul) {
    return false;
  }
  return (
    <div
      className={`${css.qrcodeMain} ${isLessThan1320 ? css.lessThan1320 : ''} ${
        isFixed ? css.fixedMain : ''
      }`}
    >
      <div className={css.stickyMain}>
        <img src={wxapp.qrcode} alt="qrcode" className={css.qrcode} />
        <p className={css.qrcodeMsg}>{wxapp.msg}</p>
      </div>
    </div>
  );
};

export default WeChat;
