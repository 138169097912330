import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { UserAgent } from '@quentin-sommer/react-useragent';
// import scrollAnimation from '~/utils/scroll-animation';
import css from './BackTop.less';

const BackTop = () => {
  const [show, setShow] = useState(false);
  const btnRef = useRef(null);

  const init = () => {
    window.addEventListener('scroll', () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 500) {
        if (!show && btnRef.current) {
          setShow(true);
        }
      } else if (show && btnRef.current) {
        setShow(false);
      }
    });
  };

  const removeEventListener = () => {
    window.removeEventListener('scroll', () => {
      return false;
    });
  };

  const backTop = () => {
    // scrollAnimation(0);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    init();
    return removeEventListener;
  }, [show]);

  const cx = classNames.bind(css);
  const btnPcClass = cx({
    content: true,
    contentPc: true,
    show,
    'bg-cover': true,
  });
  const btnMobileClass = cx({
    content: true,
    contentMobile: true,
    show,
    'bg-cover': true,
  });
  return (
    <>
      <UserAgent computer tablet>
        <button
          type="button"
          onClick={() => backTop()}
          className={btnPcClass}
          ref={btnRef}
        >
          {' '}
        </button>
      </UserAgent>
      <UserAgent mobile>
        <button
          type="button"
          onClick={() => backTop()}
          className={btnMobileClass}
          ref={btnRef}
        >
          {' '}
        </button>
      </UserAgent>
    </>
  );
};

export default BackTop;
