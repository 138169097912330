import React from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';

import css from './ServicePackage.less';

const ServicePackage = props => {
  const { index } = props;
  const { bigProducts } = index;
  return (
    <div>
      {bigProducts.length >= 1 ? (
        <div className={css.contentMain}>
          <h2 className={`mainContTitle ${css.mainContTitle}`}>
            - 服务套餐：专业顾问协助决策 -
          </h2>
          {/* 普通商品列表 */}
          <div className={css.caseBox}>
            {bigProducts.map(item => {
              return (
                <React.Fragment key={item.id.toString()}>
                  <Link
                    href={{
                      pathname: '/tuan/[id]/',
                    }}
                    as={{
                      pathname: `/tuan/${item.id}/`,
                    }}
                    passHref
                  >
                    <a className={css.caseItem}>
                      <div
                        className={`bg-cover ${css.headimg}`}
                        style={{
                          backgroundImage: `url(${item.big_cover_url})`,
                        }}
                      >
                        {' '}
                      </div>
                      {item.labels.length ? (
                        <div className={css.tagsBox}>
                          <span className={css.tag}>{item.labels[0]}</span>
                        </div>
                      ) : null}
                      <p className={`text-hidden-2 ${css.caseTitle}`}>
                        {item.title}
                      </p>
                      <p className={css.casePrice}>
                        <span>¥</span>
                        {item.price}
                      </p>
                      <p className={`text-hidden-one ${css.caseSort}`}>
                        共 {item.sold_num} 位妈妈已购买
                      </p>
                    </a>
                  </Link>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default connect(({ index }) => ({
  index,
}))(ServicePackage);
