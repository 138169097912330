import React from 'react';
import { NextSeo } from 'next-seo';
import { connect } from 'react-redux';
import LayoutIndex from '~/components/Layouts/LayoutIndex/LayoutIndex';
import HotLine from '~/components/index/HotLine/HotLine';
import Advantage from '~/components/index/Advantage/Advantage';
import Weike from '~/components/index/Weike/Weike';
import Group from '~/components/index/Group/Group';
import Platform from '~/components/index/Platform/Platform';
import ServicePackage from '~/components/index/ServicePackage/ServicePackage';
import Bargain from '~/components/index/Bargain/Bargain';

// eslint-disable-next-line
const HomeUpgrade = () => {
  return (
    <p
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage:
          'url("https://weapp-assets.xindebaby.com/web-upgrading-210726.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  );
};

const Home = () => {
  return (
    <LayoutIndex>
      <NextSeo
        title="新得宝贝 - 母婴服务放心选"
        description="MUA孕育守护是新得宝贝旗下的孕产服务优选平台。帮助孕期及产后的妈妈优选私立医院、月子会所、月嫂、孕期摄影、新生儿摄影、产后修复、亲子游泳、早教等孕产服务。"
        additionalMetaTags={[
          {
            name: 'keywords',
            content:
              '新得宝贝,MUA孕育守护,北京建档,北京月嫂,北京育儿嫂,北京私立医院,北京产后修复,北京亲子游泳,北京儿童摄影,北京早教,北京怀孕',
          },
        ]}
      />
      {/* 限时特价 */}
      <Bargain />
      {/* 服务套餐 */}
      <ServicePackage />
      {/* 平台服务介绍 */}
      <Platform />
      {/* 微信社群组件 */}
      <Group />
      {/* 微课模块组件 */}
      <Weike />
      {/* 服务流程组件 */}
      <Advantage />
      {/* 客服热线组件 */}
      <HotLine />
    </LayoutIndex>
  );
};

Home.getInitialProps = async ({ pathname, query, dvaStore }) => {
  // dispatch effects to fetch data here
  await dvaStore.dispatch({ type: 'index/getStatic' });
  await dvaStore.dispatch({ type: 'index/getSku' });
  return {
    pathname,
    query,
    dvaStore,
  };
};

export default connect(({ index, city }) => ({
  index,
  city,
}))(Home);
