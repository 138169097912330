import React, { useState } from 'react';
import css from './HotLine.less';

const HotLine = () => {
  const [remark] = useState('如需帮助，敬请拨打客服热线');
  const [phone] = useState('18600561983');
  const [workTime] = useState('9:30 ~ 18:30');

  return (
    <div className={css.contentMain}>
      <i className={css.remarkIcon}> </i>
      <p className={css.remark}>{remark}</p>
      <p className={`dinMedium ${css.phone}`}>{phone}</p>
      <p className={css.workTime}>( 工作时间：{workTime} )</p>
    </div>
  );
};

export default HotLine;
