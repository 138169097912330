import Head from 'next/head';
import Banner from '~/components/index/Banner/Banner';
import Footer from '~/components/Footer/Footer';
import BackTop from '~/components/BackTop/BackTop';
import WeChat from '~/components/index/WeChat/WeChat';

import css from './LayoutIndex.less';

const React = require('react');

const Favicon = require('public/favicon.ico');

const LayoutIndex = props => {
  const { children } = props;
  return (
    <div className={css.pageMain}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, height=device-height"
        />
        <meta name="renderer" content="webkit" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <link rel="shortcut icon" href={Favicon} type="image/x-icon" />
        <link rel="stylesheet" href="/style/desktop/reset.css" />
        <link rel="stylesheet" href="/style/desktop/common.css" />
      </Head>
      {/* banner container */}
      <Banner />
      <div className={css.content}>
        <div className={css.contentMain}>{children}</div>
      </div>
      {/* 页面公用底部组件 */}
      <Footer hasPartners />
      {/* 返回页面顶部组件 */}
      <BackTop />
      {/* 首页微信小程序二维码浮层 */}
      <WeChat />
    </div>
  );
};

export default LayoutIndex;
