import React, { useState } from 'react';
import LayerA from '~/components/Layers/LayerA/LayerA';

import css from './Group.less';

const avatar1 = require('~/static/group/avatar_1.png');
const avatar2 = require('~/static/group/avatar_2.png');
const avatar3 = require('~/static/group/avatar_3.png');
const avatar4 = require('~/static/group/avatar_4.png');
const avatar5 = require('~/static/group/avatar_5.png');
const avatar6 = require('~/static/group/avatar_6.png');
const usersBtn = require('~/static/index/users_btn.png');

const Group = () => {
  const [layerData, setLayerData] = useState({
    visible: false,
    qrcode: '/img/join_group.jpg',
    msg: '扫描上方二维码',
    desc: '微信扫描二维码，进入小程序',
  });

  const [groupList] = useState([
    {
      title: '同月妈妈群',
      desc: '同样阶段，有问题抱团解决，畅聊你的怀孕心得，交流育儿体验',
      headimg: avatar1,
      users: [
        {
          avatar:
            'https://avatar-img.xindebaby.com/c4ca4238a0b923820dcc509a6f75849b1569327595?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/c81e728d9d4c2f636f067f89cc14862c1568208281.51?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/eccbc87e4b5ce2fe28308fd9f2a7baf31568971738?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/e4da3b7fbbce2345d7772b0674a318d51568968264?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/9f61408e3afb633e50cdf1b20de6f4661569314760?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/03afdbd66e7929b125f8597834fa83a41561939788.68?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
      ],
    },
    {
      title: '同医院群',
      desc: '交流产检、分娩、产后育儿相关经验',
      headimg: avatar2,
      users: [
        {
          avatar:
            'https://avatar-img.xindebaby.com/fbd7939d674997cdb4692d34de8633c41568699327?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/43ec517d68b6edd3015b3edc9a11367b1564034322.04?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/fe695937ccc13a8d7bd3ef1b2453a83e1532278939.48?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/038498179d3054f1bd8cae93668043a91528995840.29?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/5ef059938ba799aaa845e1c2e8a762bd1566305087.36?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/e00da03b685a0dd18fb6a08af0923de01565851997.96?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
      ],
    },
    {
      title: '团购群',
      desc: '定期分享好物、团购更省心，欢迎入群一起团购',
      headimg: avatar3,
      users: [
        {
          avatar:
            'https://avatar-img.xindebaby.com/1385974ed5904a438616ff7bdb3f74391568278658.05?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/06409663226af2f3114485aa4e0a23b41568946906?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/9872ed9fc22fc182d371c3e9ed3160941556094479.3?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/7f03506a26024776ed1a688b06d2383d1545372689.86?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/f84cab266fa08788b48f8ee1c17edf751551695273.91?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/a4f23670e1833f3fdb077ca70bbd5d661554044806.65?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
      ],
    },
    {
      title: '找月嫂群',
      desc: '交流找月嫂的心得，一起避坑，安心度过月子期',
      headimg: avatar4,
      users: [
        {
          avatar:
            'https://avatar-img.xindebaby.com/918317b57931b6b7a7d29490fe5ec9f91566522070.17?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/496e05e1aea0a9c4655800e8a7b9ea281561531362.94?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/f61d6947467ccd3aa5af24db320235dd1563175351.76?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/6674eb60e0d4cd71a6eef7d7fd12bbe01532017796.67?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/298f95e1bf9136124592c8d4825a06fc1566527204.25?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/561b517c99f546f65eba9c3a491c21171534840827.87?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
      ],
    },
    {
      title: '营养答疑群',
      desc: '关于营养的有关知识解答，欢迎入群一起交流心得',
      headimg: avatar5,
      users: [
        {
          avatar:
            'https://avatar-img.xindebaby.com/8187347472d4db4cc514ea36f52cc6fd1550115445.64?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/4e4b5fbbbb602b6d35bea8460aa8f8e51567395715.19?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/99c5e07b4d5de9d18c350cdf64c5aa3d1557915886.68?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/8bcf0cebafef7d7cdf10af6dae23e51f1552962397.95?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/30bb3825e8f631cc6075c0f87bb4978c1560413334.11?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/45645a27c4f1adc8a7a835976064a86d1564616111.31?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
      ],
    },
    {
      title: '福利群',
      desc: '定期分享独家福利优惠信息，欢迎入群一起抢福利',
      headimg: avatar6,
      users: [
        {
          avatar:
            'https://avatar-img.xindebaby.com/d2ede5c73035e8737978f5cedac487541550221788.47?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/4ffce04d92a4d6cb21c1494cdfcd6dc11560827590.37?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/26408ffa703a72e8ac0117e74ad46f331565755514.38?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/8757150decbd89b0f5442ca3db4d0e0e1567077397.04?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/72ed3b86c351e5569685e92d5ab608cf1530489675.24?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
        {
          avatar:
            'https://avatar-img.xindebaby.com/556f391937dfd4398cbac35e050a21771565922630.11?imageMogr2/auto-orient/thumbnail/!96x96r/gravity/Center/crop/96x96',
        },
      ],
    },
  ]);

  const showLayer = () => {
    setLayerData({
      ...layerData,
      visible: true,
    });
  };

  const hideLayer = () => {
    setLayerData({
      ...layerData,
      visible: false,
    });
  };

  return (
    <div>
      {groupList.length >= 1 ? (
        <div className={css.contentMain}>
          <h2 className={`mainContTitle ${css.mainContTitle}`}>
            - 微信社群：有问题，抱团解决 -
          </h2>
          {/* 社群列表 */}
          <ul className={`flex-space-between ${css.caseBox}`}>
            {groupList.map(item => {
              return (
                <li className={css.caseItem} key={item.title}>
                  <div className={css.infoBox}>
                    <div
                      className={`bg-cover ${css.avatarBox}`}
                      style={{ backgroundImage: `url(${item.headimg})` }}
                    >
                      {' '}
                    </div>
                    <p className={`text-hidden-one ${css.csaeTitle}`}>
                      {item.title}
                    </p>
                    <p className={`text-hidden-2 ${css.csaeDesc}`}>
                      {item.desc}
                    </p>
                  </div>
                  <div className={css.caseBottom}>
                    {item.users.map((items, index) => {
                      return (
                        <img
                          src={items.avatar}
                          alt="avatar"
                          className={css.userAvatar}
                          key={index.toString()}
                        />
                      );
                    })}
                    <img
                      src={usersBtn}
                      alt="avatar"
                      className={css.userAvatarBtn}
                    />
                    <div className={css.btnBox}>
                      <button
                        type="button"
                        className={css.joinGroupBtn}
                        onClick={() => showLayer()}
                      >
                        加入群聊
                      </button>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
      <LayerA
        visible={layerData.visible}
        qrcode={layerData.qrcode}
        msg={layerData.msg}
        desc={layerData.desc}
        showLayer={showLayer}
        hideLayer={hideLayer}
      />
    </div>
  );
};

export default Group;
