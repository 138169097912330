import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';
import css from './Category.less';

const Category = props => {
  const [currentActiveTab, setCurrentActiveTab] = useState(null);
  let hoverTimer = null;

  const clearHoverTimer = () => {
    if (hoverTimer) {
      clearTimeout(hoverTimer);
    }
  };

  const onMouseEnter = id => {
    if (id) {
      clearHoverTimer();
      hoverTimer = setTimeout(() => {
        setCurrentActiveTab(id);
      }, 100);
    }
  };

  const onMouseLeave = () => {
    clearHoverTimer();
    hoverTimer = setTimeout(() => {
      setCurrentActiveTab(null);
    }, 100);
  };

  useEffect(() => {
    return clearHoverTimer;
  }, []);

  const { index } = props;
  const { categorys } = index;

  return (
    <div className={css.categoryNavCont}>
      <p className={css.categoryNavTitle}>您好，请选择服务分类：</p>
      <ul className={css.categoryItemBox}>
        {categorys.map((item, indexs) => {
          if (indexs >= 9) {
            return false;
          }
          return (
            <li
              className={`${css.categoryView} ${
                currentActiveTab === item.id ? css.active : ''
              }`}
              key={indexs.toString()}
              onMouseEnter={() => onMouseEnter(item.id)}
              onMouseLeave={() => onMouseLeave()}
            >
              <Link
                href={{
                  pathname: '/category/[id]/[type]/',
                }}
                as={{
                  pathname: `/category/${item.id}/1/`,
                }}
              >
                <a className={`text-hidden-one ${css.categoryItem}`}>
                  {item.name}
                </a>
              </Link>
              {/* 二级菜单 */}
              {item.show_categorys.length >= 1 ? (
                <div className={css.categoryDetail}>
                  {item.show_categorys.map((items, flag) => {
                    return (
                      <Link
                        href={{
                          pathname: '/category/[id]/[type]/',
                        }}
                        as={{
                          pathname: `/category/${items.id}/2/`,
                        }}
                        key={flag.toString()}
                      >
                        <a
                          className={`text-hidden-one ${css.categoryDetailItem}`}
                        >
                          {items.name}
                        </a>
                      </Link>
                    );
                  })}
                </div>
              ) : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default connect(({ index }) => ({
  index,
}))(Category);
