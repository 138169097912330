import React, { useState } from 'react';
import { connect } from 'react-redux';

import LayerA from '~/components/Layers/LayerA/LayerA';
import css from './Weike.less';

const playIcon = require('~/static/index/play_icon.png');

const Weike = props => {
  const [layerData, setLayerData] = useState({
    visible: false,
    qrcode: '/img/weike.jpg',
    msg: '扫描上方二维码',
    desc: '微信扫描二维码，进入小程序',
  });

  const { index } = props;
  const { weikeList } = index;
  return (
    <div>
      {weikeList.length >= 1 ? (
        <div className={css.contentMain}>
          <h2 className={`mainContTitle ${css.mainContTitle}`}>
            - MUA微课：覆盖孕育的方方面面 -
          </h2>
          {/* 推荐微课位 */}
          <div className={css.recommendBox}>
            <div
              className={`bg-cover ${css.avatarBox}`}
              style={{ backgroundImage: `url(${weikeList[0].cover_url})` }}
            >
              {' '}
            </div>
            <p className={`text-hidden-one ${css.recommendTitle}`}>
              {weikeList[0].title}
            </p>
            <p className={`text-hidden-one ${css.recommendDesc}`}>
              {weikeList[0].index_desc}
            </p>
            <button
              type="button"
              className={css.playBtn}
              onClick={() =>
                setLayerData({
                  ...layerData,
                  visible: true,
                })
              }
            >
              立即播放
            </button>
          </div>
          {/* 微课列表 */}
          <ul className={`flex-space-between ${css.caseBox}`}>
            {weikeList.map((item, indexs) => {
              return (
                <li
                  className={`${css.caseItem} ${indexs >= 1 ? '' : css.hiden}`}
                  key={item.title}
                >
                  <div
                    className={`bg-cover ${css.avatarBox}`}
                    style={{ backgroundImage: `url(${item.cover_url})` }}
                  >
                    <div className={`flex-center-center ${css.layer}`}>
                      <button
                        className={css.clear}
                        type="button"
                        onClick={() =>
                          setLayerData({
                            ...layerData,
                            visible: true,
                          })
                        }
                      >
                        <img
                          className={css.caseIcon}
                          alt="avatar"
                          src={playIcon}
                        />
                      </button>
                    </div>
                  </div>
                  <p className={`text-hidden-2 ${css.caseTitle}`}>
                    {item.title}
                  </p>
                  <p className={`text-hidden-one ${css.caseMsg}`}>
                    {item.index_desc}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
      <LayerA
        visible={layerData.visible}
        qrcode={layerData.qrcode}
        msg={layerData.msg}
        desc={layerData.desc}
        showLayer={() =>
          setLayerData({
            ...layerData,
            visible: true,
          })
        }
        hideLayer={() =>
          setLayerData({
            ...layerData,
            visible: false,
          })
        }
      />
    </div>
  );
};

export default connect(({ index }) => ({
  index,
}))(Weike);
