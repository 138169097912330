import React, { useState } from 'react';
import LayerC from '~/components/Layers/LayerC/LayerC';

import css from './Platform.less';

const imagePlaceholder = require('~/static/index/image_placeholder.png');
const playIcon = require('~/static/index/play_icon.png');
const scanIcon = require('~/static/index/scan_icon.png');

const jiandangQrcode = require('~/static/qrcode/jiandang.png');
const maternityQrcode = require('~/static/qrcode/maternity.png');
const vaccine = require('~/static/qrcode/vaccine.png');

// 背景图片
const platform1 = require('~/static/index/platform_1.png');
const platform2 = require('~/static/index/platform_2.png');
const platform3 = require('~/static/index/platform_3.png');
const platform4 = require('~/static/index/platform_4.png');
const platform5 = require('~/static/index/platform_5.png');
const platform6 = require('~/static/index/platform_6.png');
const platform7 = require('~/static/index/platform_7.png');

const Platform = () => {
  const [videoJsOptions, setVideoJsOptions] = useState({
    autoplay: true,
    controls: true,
    width: 900,
    sources: [
      {
        src: '',
        type: 'video/mp4',
      },
    ],
    controlBar: {
      pictureInPictureToggle: false,
    },
  });

  const [layerData, setLayerData] = useState({
    visible: false,
  });

  const [wxappList] = useState([
    {
      title: '孕产大事之医院建档',
      desc: '怀孕后的第一件大事建档助手，一站式建档神器。',
      qrcode: jiandangQrcode,
      bgImage: platform1,
    },
    {
      title: '孕产大事之找月嫂',
      desc: '找月嫂前你需要了解几项注意事项。',
      qrcode: maternityQrcode,
      bgImage: platform2,
    },
    {
      title: '孕产大事之宝宝疫苗',
      desc: '太多选择怎么定制方案，宝宝接种疫苗助手又一个神器。',
      qrcode: vaccine,
      bgImage: platform3,
    },
  ]);

  const [videoList] = useState([
    {
      title: '第三方月嫂评测',
      desc: '专家评测，专业避坑，专为不懂怎么面试月嫂的你。',
      headimg: imagePlaceholder,
      src: 'https://free-video.xindebaby.com/maternity_20191010_02.mp4',
      bgImage: platform4,
    },
    {
      title: '月子会所考察团',
      desc: '专家带队，一起杀价，了解月子会所不为人知的内幕。',
      headimg: imagePlaceholder,
      src:
        'https://free-video.xindebaby.com/postpartum_care_center2020042701.mp4',
      bgImage: platform5,
    },
    {
      title: '亲子游泳体验卡',
      desc: '一卡在手，多家体验，拒绝套路，精挑细选再办卡。',
      headimg: imagePlaceholder,
      src: 'https://free-video.xindebaby.com/swimming_20191014_01.mp4',
      bgImage: platform6,
    },
    {
      title: '产后身体评估',
      desc: '权威医生全面检查您的身体，全程指导产后修复。',
      headimg: imagePlaceholder,
      src: '',
      bgImage: platform7,
    },
  ]);

  const showLayer = src => {
    setVideoJsOptions({
      ...videoJsOptions,
      sources: [
        {
          src,
          type: 'video/mp4',
        },
      ],
    });
    setLayerData({
      visible: true,
    });
  };

  const hideLayer = () => {
    setLayerData({
      visible: false,
    });
  };

  const notify = () => {
    // eslint-disable-next-line
    alert('建设中...');
  };

  return (
    <div className={css.contentMain}>
      <h2 className={`mainContTitle ${css.mainContTitle}`}>
        - 平台服务：专业中立的第三方立场 -
      </h2>
      {/* 小程序列表 */}
      <ul className={`flex-space-between ${css.caseBox}`}>
        {wxappList.map(item => {
          return (
            <li
              className={css.wxappItem}
              key={item.title}
              style={{ backgroundImage: `url(${item.bgImage})` }}
            >
              <div>
                <p className={css.wxappTitle}>{item.title}</p>
                <p className={css.wxappMsg}>{item.desc}</p>
              </div>

              <div className={css.wxappbottom}> </div>

              {/* hover二维码 */}
              <div className={`flex-column-center ${css.qrcodeBox}`}>
                <img src={scanIcon} alt="scanIcon" className={css.scanIcon} />
                <p className={css.scanMsg}>SCAN & GET THE MINI PROGRAMS</p>
                <img
                  src={item.qrcode}
                  alt="qrcode"
                  className={css.qrcodeIcon}
                />
                <p className={css.qrcodeMsg}>微信扫一扫，使用小程序</p>
              </div>
            </li>
          );
        })}
      </ul>
      {/* 视频列表 */}
      {videoList.length >= 1 ? (
        <ul className={`flex-space-between ${css.caseBox}`}>
          {videoList.map(item => {
            return (
              <li
                className={css.caseItem}
                key={item.title}
                style={{ backgroundImage: `url(${item.bgImage})` }}
                onClick={() => (item.src ? showLayer(item.src) : notify())}
              >
                <p className={css.caseTitle}>{item.title}</p>
                <p className={css.caseMsg}>{item.desc}</p>
                <img src={playIcon} alt="play" className={css.playBtn} />
              </li>
            );
          })}
        </ul>
      ) : null}
      <LayerC
        videoJsOptions={videoJsOptions}
        visible={layerData.visible}
        showLayer={showLayer}
        hideLayer={hideLayer}
      />
    </div>
  );
};

export default Platform;
