import React from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';

import css from './Bargain.less';

const Bargain = props => {
  const { index } = props;
  const { experienceProducts } = index;

  return (
    <div>
      {experienceProducts.length >= 1 ? (
        <div className={css.contentMain}>
          <h2 className={`mainContTitle ${css.mainContTitle}`}>
            - 体验团购：服务好不好，试试才知道 -
          </h2>
          {/* 体验团购 */}
          <div className={css.caseBox}>
            {experienceProducts.map(item => {
              return (
                <React.Fragment key={item.id.toString()}>
                  <Link
                    href={{
                      pathname: '/tuan/[id]/',
                    }}
                    as={{
                      pathname: `/tuan/${item.id}/`,
                    }}
                    passHref
                  >
                    <a className={css.caseItem}>
                      <div
                        className={`bg-cover ${css.headimg}`}
                        style={{
                          backgroundImage: `url(${item.experience_cover_url})`,
                        }}
                      >
                        {' '}
                      </div>
                      {item.labels.length ? (
                        <div className={css.tagsBox}>
                          <span className={css.tag}>{item.labels[0]}</span>
                        </div>
                      ) : null}
                      <p className={`text-hidden-2 ${css.caseTitle}`}>
                        {item.title}
                      </p>
                      <p className={css.casePrice}>
                        <span className={css.unit}>¥</span>
                        <span className={css.price}>{item.price}</span>
                        <span className={css.originalPrice}>
                          ¥{item.market_price}
                        </span>
                      </p>
                      <p className={`text-hidden-one ${css.caseSort}`}>
                        共 {item.sold_num} 位妈妈已购买
                      </p>
                    </a>
                  </Link>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default connect(({ index }) => ({
  index,
}))(Bargain);
