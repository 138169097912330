import React from 'react';
import VideoPlayer from '~/components/VideoPlayer/VideoPlayer/';
import css from './LayerC.less';

const closeIcon = require('~/static/common/close.png');

class LayerC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // visible: false
    };
  }

  // eslint-disable-next-line
  handleClick(event) {
    const e = event || window.event;
    if (e.stopPropagation) {
      e.stopPropagation();
    } else {
      e.cancaleBubble = true;
    }
  }

  render() {
    const { videoJsOptions, visible, hideLayer } = this.props;
    return (
      // eslint-disable-next-line
      <div
        className={`${css.layer} ${visible ? css.show : ''}`}
        onClick={hideLayer}
      >
        <button className={css.clear} type="button" onClick={hideLayer}>
          <img src={closeIcon} alt="close" className={css.close} />
        </button>
        {/* eslint-disable-next-line */}
        <div
          className={`flex-column-center ${css.main}`}
          onClick={e => this.handleClick(e)}
        >
          {visible ? <VideoPlayer {...videoJsOptions} /> : null}
        </div>
      </div>
    );
  }
}

export default LayerC;
