import React from 'react';
import css from './LayerA.less';

const xindeQrcode = require('~/static/qrcode/xinde.png');
const closeIcon = require('~/static/common/close.png');

class LayerA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('keydown', event => {
      const e = event || window.event;
      const { hideLayer } = this.props;
      if (e && e.keyCode === 27 && this._isMounted) {
        hideLayer();
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('keydown', () => {
      return false;
    });
  }

  // eslint-disable-next-line
  handleClick(event) {
    const e = event || window.event;
    if (e.stopPropagation) {
      e.stopPropagation();
    } else {
      e.cancaleBubble = true;
    }
  }

  render() {
    const { qrcode, msg, desc, visible, hideLayer } = this.props;
    return (
      // eslint-disable-next-line
      <div
        className={`${css.layer} ${visible ? css.show : ''}`}
        onClick={hideLayer}
      >
        <button className={css.clear} type="button" onClick={hideLayer}>
          <img src={closeIcon} alt="close" className={css.close} />
        </button>
        {/* eslint-disable-next-line */}
        <div
          className={`flex-column-center ${css.main}`}
          onClick={e => this.handleClick(e)}
        >
          <img
            src={qrcode || xindeQrcode}
            alt="qrcode"
            className={css.qrcode}
          />
          <span className={css.msg}>{msg || '扫描上方二维码'}</span>
          <span className={css.desc}>
            {desc || '微信扫描二维码，进入小程序'}
          </span>
        </div>
      </div>
    );
  }
}

export default LayerA;
